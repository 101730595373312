// extracted by mini-css-extract-plugin
export var bChildren = "term-module--bChildren--wwGzo";
export var buttonContainer = "term-module--buttonContainer--DWFmK";
export var buttonPrivacy = "term-module--buttonPrivacy--HG2Pm";
export var fotterTerm = "term-module--fotterTerm--Ew3gQ";
export var h2Update = "term-module--h2Update--DppQV";
export var sectionChildren = "term-module--sectionChildren--WOhzg";
export var sectionUpdate = "term-module--sectionUpdate--lN8RA";
export var subtitle = "term-module--subtitle--0PV8r";
export var termBackground = "term-module--termBackground--9Aao-";
export var termContainer = "term-module--termContainer--XBm6y";
export var title = "term-module--title--lFR8M";
export var update = "term-module--update--vVLeT";