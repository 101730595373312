import React from 'react';
import Seo from '../components/Seo/Seo';
import Container from '../components/Container/Container';
import * as classes from './term.module.css';
import Scroll from '../components/Scroll/Scroll';

import app from '../firebase/firebase';
import { getAnalytics, logEvent } from "firebase/analytics";

const Term = () => {

    React.useEffect(() => {
      if (!app) {
        return
      }
     
      const analytics = getAnalytics(app);
      logEvent(analytics, 'visited_terms');
    }, [])

  return (
    <div className={classes.termBackground}>
    <Scroll />
    <Container>
      <Seo title={`Terms`}/>
          <div className={classes.termContainer}>
          <span className={classes.title}>Terms of Use</span>
          <span className={classes.update}>updated August, 2022</span>
          <section style={{marginTop: "2.2rem"}}>
                    <p>
                    The following key points of the Terms of Use are only brought for your convenience.
                      <br/> 
                      These key points do not substitute the full Terms.
                      <ul>
                        <li>Welcome to KChat, an online platform for kids to communicate with friends under parents supervision.</li>
                        <li>You may only use the Service for personal purposes.</li>
                        <li>Use of the Service requires registration and creation of an account.</li>
                        <li>You may only use the Service in accordance with the Terms and applicable law.</li>
                        <li>You may only post lawful, non-infringing content on the Service.</li>
                        <li>Content that you share on the Service is treated as private and confidential within the group it is shared with. Our Privacy Policy, which is incorporated to these Terms by reference, explains the privacy practices on the Service.</li>
                        <li>THE TERMS INCLUDE ADDITIONAL PROVISIONS THAT YOU SHOULD CAREFULLY READ, SUCH AS PROVISIONS REGARDING WARRANTY, LIMITED LIABILITY, INDEMNIFICATION AND ASSIGNMENT.</li>
                      </ul>
                    </p>
          </section>
          <section>
                    <span className={classes.subtitle}>Welcome</span>
                    <p>
                    Welcome to KChat, an online platform for kids to communicate with friends under parents supervision (the Service)
                      <br/>
                      The Service is owned and operated by iFlores S.A. (we, our, us). Our designated applications for mobile devices(Device), whichconstitute integral componentsof the Service, will be referred to as the App or Apps
                      <br/>
                      Please carefully read the following Terms of Use (the Terms). By using or accessing the Service, you agree to be bound by the Terms. If you donotagreeto the Terms,you may not use the Service.
                      <br/>
                      Note that use of the Service may involve the use of third party platforms, such as Facebook, Whatsapp, Telegram, Google etc. For example, you may share groups or invite people to the Service, through these third party platforms. Use of such third party platforms is governed by their respective terms of service, not these Terms. You bear the sole and exclusive responsibility for complying with those other terms of service.
                    </p>
                </section>
                <section>
                    <span className={classes.subtitle}>Use</span>
                    <p>
                    Subject to these Terms, you may download and install the Apps on a Device, and use the Service exclusively for your personal purposes.
                    </p>
                </section>
                <section>
                    <span className={classes.subtitle}>Use of the Service and restrictions</span>
                    <p>
                    While using the Service, you agree to refrain from -
                       <ul>
                        <li>Breaching the Terms or any other applicable rules and instructions that we may convey with respect to the use of the Service;</li>
                        <li>Engaging in any activity that constitutes or encourages conduct that would constitute a criminal offense, give rise to civil liability or otherwise violate any applicable law, including laws governing privacy, defamation, spam and copyright.</li>
                        <li>Interfering with, burdening or disrupting the functionality of the Service;</li>
                        <li>Breaching the security of the Service or identifying any security vulnerabilities in it;</li>
                        <li>Circumventing or manipulating the operation or functionality of the Service, or attempting to enable features or functionalities that are otherwise disabled, inaccessible or undocumented in the Service;</li>
                        <li>Using or launching any automated system, including without limitation robots, crawlers and similar applications to collect or compile content from the Service;</li>
                        <li>Displaying the Service or any part thereof in an exposed or concealed frame,or linking to elements or portions on or of the Service,independently from manner on which they originally appear or are made available through the Service; </li>
                        <li>Impersonating any person or entity,or making any false statement pertaining to your identity, employment, agency or affiliation with any person or entity;</li>
                        <li>Collecting, harvesting, obtaining or processing personal information on or about other users of the Service.</li>
                       </ul>

                       Without derogating from any of the above, you may not post, disseminate,transmit or otherwise communicate through the Service any Content which:
                       <ul>
                        <li>May infringe rights of others, including patents, copyrights, trade secrets, trademarks, a person's right to privacy or right of publicity;</li>
                        <li>May depict or identify minors, their personal details, their address or ways to contact them;</li>
                        <li>May include software viruses, spyware or any other malicious applications;</li>
                        <li>May encourage,support,assist,or advise in the commission of a criminal offense or carry an elevated risk of psychological or physiological stress or injury or damage to property;</li>
                        <li>May, through posting, publication, dissemination, or making available to the public, be prohibited by any applicable law, including court orders;</li>
                        <li>May be threatening, abusive, harassing, defamatory, libelous, vulgar, violent, obscene or racially, ethnically or otherwise objectionable;</li>
                        <li>May include unsolicited commercial communications ('spam'), chain letters, or pyramid schemes.</li>
                       </ul> 

                       You may not access or use the Service in order to develop or create a similar or competitive product or service.
                      <br/>
                      You may not use the Service to send invitations or notifications to anyone who has not expressed their consent to receive such invitations or notifications from you. 
                      <br/>
                      We expect our users to avoid uploading inappropriate content such as nudes and porn, violence, terror, bullying, private content or content that breaches a third party rights. 
                      <br/>
                      We encourage respectful behavior. If you receive or become aware of any content through the Service that violates these Terms, please let us know by contacting us at: support@knesis.com. We will review every request and take action as we deem appropriate.
                    </p>
                </section>
                <section>
                    <span className={classes.subtitle}>Content and dealings</span>
                    <p>
                    Content presented through the Service originates from you or from third parties such as other users. You may find that the Content is not compatible with your requirements, objectionable,annoying, improper, or immoral. 
                       <br/>
                      <b>
                      We encourage you to treat any Content posted on or through the Service with caution and discretion. We do not guarantee and make no representation or judgment as to the Content’s legality, appropriateness, authenticity, validity, accuracy, truthfulness, morality, adequacy to your age or needs, uses thereof that third parties may make, or as to any offerings, dealings, transactions or interactions made by orbetween you and third parties in connection with the Service.
                      </b>
                      <br/>
                      The inclusion of any Content on or through the Service does not constitute our endorsement, sponsorship, recommendation or encouragement to engage in any interaction, transaction or dealings with any third party, or an authorization or representation of our affiliation with any third party.
                        <br/>
                        Any and all communications and interactions between you and third parties, and all consequences resulting from the above, are strictly between you and such thirdparty, and you assume full and exclusive responsibility for them. We are not a party tothose communications, interactions, dealings, engagements and transactions.
                        <br/>
                        We do not normally examine, approve or disqualify Content that you or others have uploaded or posted, but we reserve the right to do so.
                        <br/>
                        If you see any inappropriate Content/Stickers or any violations of these Terms, please Report it.
                        <br/>
                        You can, at any time, delete Content/ you uploaded to the app. Note, Content you deleted from the app will be deleted just in the app and it won’t be deleted on third party apps, such as WhatsApp if you shared it there.
                        <br/>
                        We do not claim ownership over the Content that you create, upload to or post on the Service. WHEN YOU USE THE SERVICE TO DO THIS, YOU REPRESENT AND WARRANT TO US THAT: 
                      
                      <ul>
                        <li>You are the rightfu lowner of all rights to such Content,or are lawfully licensed by all the rightful owners to upload to, and post such Content on, the Service;</li>
                        <li>You are lawfully entitled to grant us the licenses in and to such Content, as contemplated by these terms;</li>
                        <li>You lawfully obtained the consent of all individuals depicted, shown or referred to in the Content, to include them in the Content and to use that Content for the purposes and in the manner contemplated by these terms. With respect to minors depicted, shown or referred to in the Content, you lawfully obtained the consent of their parents or legal guardians, as well as such minors approval;</li>
                        <li>Creating the content, uploading it to and posting it on the Service, does not infringe any rights of third parties, including intellectual property rights, privacy rights and publicity rights. </li>
                       </ul>

                       Subject to your rights and undertakings under the Privacy Policy, by posting or uploading Content on or to the Service, you grant us a royalty-free, free-of-charge, worldwide, non-exclusive, sub-licensable and transferable, license to use such Content (including commercial use as well as copying, distributing,postingand making derivative works or charge money from other users for the use of it),on or through the Service and on any other online or offline platform or media, for as long as you have not deleted such Content from the Service. You further waive all moral rights and rights of attribution with respect to your Content. 
                        <br/>
                        We may, at any time, at our sole discretion and without prior notice to you, remove, hide, disable, render inaccessible, or modify any Content you have created, uploaded, streamed or posted on or through the Service, without any liability to you.
                    </p>
                </section> 
                <section>
                    <span className={classes.subtitle}>Your privacy</span>
                    <p>
                    We respect your privacy. Our Privacy Policy, which is incorporated to these Terms by reference, explains the privacy practices on the Service.
                    </p>
                </section> 
                <section>
                    <span className={classes.subtitle}>Intellectual property</span>
                    <p>
                    We and our licensors own all rights, title and interest in and to the Service, including patents, copyrights, trademarks, trade names, service marks, trade secrets and other intellectual property rights, and any goodwill associated therewith. 
                      <br/>
                      Unless as expressly permitted in the Terms, you may not copy, distribute, display or perform publicly, sublicense, decompile, disassemble, reduce to human readable form, execute publicly, make available to the public, adapt, make commercial use of, process, compile, translate, sell, lend, rent, reverse engineer, combine with other software, modify or create derivative works oftheService, any part thereof or any of the Content on or of the Service, either by yourself or by a third party on your behalf,in any way or by any means, including, but not limited to electronic, mechanical or optical means.
                      <br/>
                      You may not adapt or otherwise use,including in any Internet domain name,any name, mark, stickers or logo that is similar to our marks and logos.You must refrain from any action or omission which may dilute, or tarnish our goodwill.
                    </p>
                </section> 
                <section>
                    <span className={classes.subtitle}>Changes in the Service; discontinuation</span>
                    <p>
                    We may, but are not obligated to, maintain the Service with periodic releases of bug fixes, code updates or upgrades. We will determine, in our discretion, the frequency and scope of such releases and you will have no plea, claim or demand against us or our directors, officers, employees, agents, advisors, consultants, subcontractors and assignees (collectively, our Staff), for any of these releases or the lack thereof.
                      <br/>
                      You grant us your express consent to remotely send and automatically install on your Device, without any prior or additional notice, updates, upgrades, code modifications, enhancements, bug fixes, improvements and any other form of code or settings changes in or to the App, which, among other things, may change the App's settings, layout, design or display.
                      <br/>
                      We may also, at any time and without prior notice, change the layout, design, scope, features or availability of the Service or the Content.
                      <br/>
                      Such changes, by their nature, may cause inconvenience or even malfunctions. YOU AGREE AND ACKNOWLEDGE THAT WE DO NOT ASSUME ANY RESPONSIBILITY WITH RESPECT TO, OR IN CONNECTION WITH THE INTRODUCTION OF SUCH CHANGES OR FROM ANY MALFUNCTIONS OR FAILURES THAT MAY RESULT THEREFROM.
                      <br/>
                      We may, at any time, at our sole discretion, discontinue, terminate or suspend the operation of the Service, or any part or aspects thereof, temporarily or permanently, without any liability to you.
                    </p>
                </section> 
                <section>
                  <span className={classes.subtitle}>Service support, availability and quality</span>
                  <p>
                  The availability, quality and functionality of the Service depends on various factors, including software, hardware, communication networks, and the quality of cellular network connectivity, which are provided by third parties, at their responsibility. These factors are not fault-free. 
                    <br/>
                    WE DO NOT WARRANT THAT THE SERVICE WILL OPERATE WITHOUT DISRUPTION, ERRORS OR INTERRUPTIONS, OR THATITWILL BE ACCESSIBLE,OR AVAILABLE AT ALL TIMES OR IMMUNE FROM ERRORS, GLITCHES OR UNAUTHORIZED ACCESS. 
                    <br/>
                    You acknowledge that the Service DOES NOT provide any data back-up services, including with respect to any Content or any other data that you upload or use.
                  </p>
                </section>
                <section>
                  <span className={classes.subtitle}>Changes to the Terms</span>
                  <p>
                  We may amend the Terms from time to time. In such a case, we will put you on notice of the amended Terms. If you do not accept the amended Terms, we may terminate the Terms and your User Account and block your access to, and use of, the Service. Your continued use of the Service after the effective date of the amended Terms constitutes your consent to the amended Terms. The latest version of the Terms will be accessible through the App.
                  </p>
                </section>
                <section>
                  <span className={classes.subtitle}>DISCLAIMER OF WARRANTY</span>
                  <p>
                  THE SERVICE IS PROVIDED FOR USE “AS IS” AND “WITH ALL FAULTS”. WE AND OUR STAFF DISCLAIM ALL WARRANTIES AND REPRESENTATIONS, EITHER EXPRESS OR IMPLIED,WITH RESPECT TO THE SERVICE,THE APPS,THE CONTENT, YOUR USER ACCOUNT, ANY INTERACTION RELATED TO THE SERVICE, ANY THIRD PARTY SOFTWARE OR HARDWARE, INCLUDING ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUALITY, RELIABILITY, NON-INFRINGEMENT, TITLE, COMPATIBILITY, PERFORMANCE, AVAILABILITY, SAFETY, SECURITY OR ACCURACY.
                  </p>
                </section>
                <section>
                  <span className={classes.subtitle}>LIMITATION OF LIABILITY </span>
                  <p> WE, INCLUDING OUR STAFF, SHALL NOT BE LIABLE TO THE MAXIMUM EXTENT PERMITTED BY THE APPLICABLE LAW, FOR ANY DIRECT, INDIRECT, SPECIAL, PUNITIVE, EXEMPLARY, STATUTORY, INCIDENTAL OR CONSEQUENTIAL DAMAGE, OR ANY SIMILAR DAMAGE OR LOSS(INCLUDING LOSS OF PROFIT AND LOSS OF DATA), COSTS, EXPENSES AND PAYMENTS, EITHER INTORT, CONTRACT, OR IN ANY OTHER FORM OR THEORY OF LIABILITY (INCLUDING NEGLIGENCE), ARISING FROM, OR IN CONNECTION WITH THE SERVICE, THE CONTENT, THE USE OF, OR THE INABILITY TO USE THE SERVICE OR YOUR USER ACCOUNT, OR ANY COMMUNICATION OR INTERACTIONS WITH THE SERVICE, OR ANY COMMUNICATIONS, INTERACTIONS, DEALINGS, ENGAGEMENTS AND TRANSACTIONS WITH OTHERS ON OR THROUGH THE SERVICE, OR YOUR RELIANCE UPON ANY CONTENT POSTED OR AVAILABLE ON THE SERVICES, OR ANY FAILURE, ERROR, OR BREAKDOWN IN THE FUNCTION OF THE SERVICE, OR ANY RELIANCE MADE BY YOU ON THIRD PARTY SOFTWARE OR HARDWARE, OR ANY FAULT, OR ERROR MADE BY OUR STAFF,OR ANY DENIAL OR CANCELATION OF YOUR USER ACCOUNT, OR RETENTION, DELETION, DISCLOSURE AND ANY OTHER USE OR LOSS OF YOUR CONTENT ON THE SERVICE.</p>
                </section>
                <section>
                  <span className={classes.subtitle}>Indemnification</span>
                  <p>
                  To the maximum extent permitted by law, you agree to indemnify, defend and hold harmless, us and our Staff, at your own expense and immediately after receiving a written notice thereof, from and against any damages, loss, costs and expenses, including attorney’s fees and legal expenses, resulting from any plea, claim, allegation or demand, arising from, or in connection with your use of the Service, any Content, your breach of the Terms, any other terms, rules or regulations applicable to the Service, your violation, or infringement of any other person’s rights.
                  </p>
                </section>
                <section>
                  <span className={classes.subtitle}>Links and Commercial Information in the Service </span>
                  <p>The Service may contain links to content published on other websites or external sources, provided by third parties. We do not operate, or monitor these websites and content. You may find them or the information and content posted therein not compatible with your requirements, or you may object to their content, or find such content to be annoying, improper, unlawful or immoral. By linking to a certain website, we do not endorse, or sponsor its content, or confirm its accuracy, credibility, authenticity, reliability, validity, integrity, or legality. We assume no responsibility or liability for such third party websites or content, or their availability.</p>
                </section>
                <section>
                  <span className={classes.subtitle}>Application Marketplace</span>
                  <p>
                  Your use of the App may be subject to additional third party terms and conditions that govern that application marketplace from which you downloaded the App, such as iTunes for iOS and Google Play or Amazon App-store for Android. Such third parties are not responsible for providing maintenance and support services with respect to the App.
                      <br/>
                      The following terms apply if you downloaded an App from Apple's App Store. You and us agree and acknowledge as follows:
                      <br/>
                      These Terms are concluded between yourself and us,andnotwithApple Inc.(Apple). Apple is not responsible for the App. In the event of a conflict between these Terms and the App Store Terms of Service then the App Store TermsofService will prevail,solely with respect to the conflicting provisions. 
                      <br/>
                      The license granted to you for the App is limited to a non-transferable license to use the App on any iOS Products that you own or control, and as permitted by the Usage Rules set forth in the App Store Terms of Service, except that such App may be accessed, acquired, and used by other accounts associated with the purchaser via Family Sharing or volume purchasing. 
                      <br/>
                      You acknowledge that Apple has no obligation whatsoever to furnish any maintenance and support services with respect to the App.
                      <br/>
                      In the event of a failure to conform to any applicable warranty (if any warranty is applicable), you may notify Apple, and Apple will refund the purchase price for the App to you (if you paid any). Apple has no other warranty obligation whatsoever with respect to the App, and any other claims, losses, liabilities, damages, costs or expenses attributable to any failure to conform to any warranty, will not be at Apple’s responsibility.
                      <br/>
                      Apple is not responsible for addressing any claims by you or any third party relating to the App or your possession and/or use of the App, including (i) product liability claims, (ii) any claim that the App fails to conform to any applicable legal or regulatory requirement, and (iii) claims arising under consumer protection or similar legislation. 
                      <br/>
                      You acknowledge that in the event of any third party claim that the App or your possession and use of the App infringes that third party’s IP Rights, Apple will not be responsible for the investigation, defense, settlement and discharge of any such infringement claim. 
                      <br/>
                      You must comply with applicable third party terms of agreement when using the App (e.g. you must not be in violation of your wireless data Services agreement when you use the App). 
                      <br/>
                      Apple and Apple’s subsidiaries are third party beneficiaries ofthese Terms.Upon Your acceptance of these Terms, Apple will have the right (and will be deemed to have accepted the right) to enforce these Terms against you as a third party beneficiary thereof. You represent and warrant that (i) you are not located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a “terrorist supporting” country; and (ii) you are not listed on any U.S. Government list of prohibited or restricted parties. 
                  </p>
                </section>
                <section>
                  <span className={classes.subtitle}>Third Party Software</span>
                  <p>
                  The app may contain or rely upon third party software necessary for its execution, including open source software (Third Party Software). The license granted to you under these terms does not cover your use of third party software. You acknowledge and agree that any access to and use of such third party software is separately licensed to you pursuant to the terms and conditions of their respective third party software licenses. For avoidance of any doubt, it is hereby clarified that your use of any of the Apps may be subject to terms and conditions that govern your Device. You bear the sole and exclusive responsibility for complying with any terms and conditions that may additionally apply.
                  </p>
                </section>
                <section>
                  <span className={classes.subtitle}>Termination of these Terms</span>
                  <p>
                  You may, at any time terminate these Terms by doing all the following:

                    <ul>
                      <li>Completely removing the App from all of your Devices; and</li>
                      <li>Providing us written notice of termination.</li>
                    </ul> 

                    We may terminate these Terms and your license to use the Service, at any time by issuing you a notice of sucht ermination, blocking your access to and use of the Service, or in any other manner contemplated by these Terms. Upon termination by The Service you must discontinue any and all use of the Service and uninstall the App or Apps from all of your Devices.
                  </p>
                </section>
                <section>
                  <span className={classes.subtitle}>Consequences of termination </span>
                  <p>
                  See our privacy policy for details regarding retention and deletion of your personal information.
                    <br/>
                    The following sections shall survive any termination, or expiration of the Terms: Content and dealings, Your privacy, Intellectual property, Copyright Policy, Limitation of Liability, Indemnification, Consequences of termination, Governing Law and Jurisdiction, General.
                  </p>
                </section>
                <section>
                  <span className={classes.subtitle}>Governing Law and Jurisdiction </span>
                  <p>
                  Regardless of your place of residence or where you access or use the Service from, these Terms and your use of the Service will be exclusively governed by and construed in accordance with the laws of Uruguay, excluding any otherwise applicable rules of conflict of laws, which would result in the application of the laws of a jurisdiction other than Uruguay.
                      <br/>
                      Before submitting any dispute, claim, or controversy arising out of, in connection with or relating to these Terms,to adjudication in court, you and us will dedicate at least two weeks to attempt to amicably resolve such matter in an informal dispute resolution process. However, nothing herein shall, in any way, affect either party’s right to seek interim or emergency relief in the court of competent jurisdiction,or ourrighttolodge a claim against you pursuant to the indemnity clause above,in any court adjudicating a third party claim against us – all, without referring the matter to amicable resolution pursuant to the foregoing.
                      <br/>
                      The competent courts located in the Montevideo district will have exclusive and sole jurisdiction over any dispute, claim or controversy arising from, or in connection with, the Service and its use, and with respect to any matter relating to the validity, applicability, performance or interpretation of these Terms. You and us, each, hereby expressly consent to personal jurisdiction in Uruguay and expressly waive any right to object to such personal jurisdiction or the non-convenience of such forum.
                  </p>
                </section>
                <section>
                  <span className={classes.subtitle}>General</span>
                  <p>
                  Whenever used in these Terms, the term Including, whether capitalized or not, means without limitation to the preceding phrase. All examples and e.g. notations are illustrative, not exhaustive.
                      <br/>
                      These Terms constitute the entire agreement between you and us concerning the subject matter herein, and supersede all prior and contemporaneous negotiations and oral representations, agreements and statements.
                      <br/>
                      These Terms may only be modified by written amendment duly executed by us. No waiver, concession, extension, representation, alteration, addition or derogation from the Terms by us, or pursuant to the Terms, will be effective unless consented to explicitly and executed in writing by our authorized representative.
                      <br/>
                      Failure on our part to demand performance of any provision in the Terms shall not constitute a waiver of any of our rights under the Terms.
                      <br/>
                      You may not assign or transfer your rights and obligations under the Terms without our prior written consent, which consent shall not be unreasonably withheld. Any attempted or actual assignment by you, without our prior consent, shall be null and void. Notwithstanding anything to the contrary, we may assign these Terms in their entirety, including all right, duties,liabilities and obligations therein,upon notice to you and without obtaining your further specific consent, to a third-party, upon a merger, acquisition, change of control or the sale of all or substantially all of its equity or assets relating to the Agreement. By virtue of such assignment, the assignee assumes our stead, including all rights, duties, liabilities and obligations. 
                      <br/>
                      The section headings in the Terms are included for convenience only and shall take no part in the interpretation or construing of the Terms.
                      <br/>
                      If any provision of the Terms is held to be illegal, invalid, or unenforceable by a competent court, then the provision shall be performed and enforced to the maximum extent permitted by law to reflect as closely as possible, the original intention of that provision, and the remaining provisions of the Terms shall continue to remain in full force and effect.
                  </p>
                </section>
                <section>
                  <span className={classes.subtitle}>Contact Us</span>
                  <p>
                  At any time, you may contact us with any question that you may have with respect to the Service, at: support@knesis.com
                  </p>
                </section>
          </div>
    </Container>
     <footer className={classes.fotterTerm}></footer>
  </div>
  )
}

export default Term
